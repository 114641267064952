@font-face {
  font-family: 'lato-bold';
  src: URL('./fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'lato';
  src: URL('./fonts/Lato-Regular.ttf') format('truetype');
}

* {
  font-family: monospace;
  word-break: break-all;
  overflow-wrap: anywhere;
  font-size: 17px;
  /* font-family: "lato";  */
  color: rgb(89, 89, 89);
}

button > span {
  color: rgb(255, 255, 255);
}

html {
  background-color: rgb(232, 232, 232);  
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  font-size: 15px;
  font-family: monospace;
  word-break: break-all;
  overflow-wrap: anywhere;
    /* font-family: "lato";  */
  overflow-x: hidden;
}

.App {
  margin: auto;
  width: 1150px;
  background-color: rgb(255, 255, 255);
  border: 3px solid rgb(250, 250, 250);
}

header, main, footer {
  height: auto;
  width: auto;
  margin-left: 30px;
  margin-right: 30px;
}

header {
  position: relative;
  height: 100px;
}

footer {
  height: 70px;
  display: flex;
  align-items: center; /* Vertical center alignment */
  justify-content: center; /* Horizontal center alignment */
}

main {
  margin-top: 20px;
  margin-bottom: 20px;
}

.fullSeparator {
  width: 100%;
  border-bottom: 2px solid rgb(234, 234, 234);  
}

.search {
  position: absolute;
  width: 350px;
  right: 10px;
  top: 30px;
}

td {
  overflow-wrap: anywhere;
  word-break: break-all;
  white-space: normal;
}

pre {
  overflow-wrap: anywhere;
  word-break: break-all;
  white-space: pre-wrap;
}

h2 {
  font-size: 20px;
}

.logo {
  position: absolute;
  top: 30px;
  left: 10px;
  width: 150px;
  height: 40px;
}

.shortText {
  max-width: 90ch;
}